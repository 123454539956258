@import "assets/fonts/stylesheet.css";
@import "styles/overlay.css";
@import "styles/form-loader.css";
@import "styles/lang-switcher.css";
@import "styles/subscribe-form.css";
@import "styles/loader.css";
@import "styles/errormsg.css";
@import "styles/main.css";


body, #root {
    margin: 0;
    font-size: 21px;
    font-family: 'Basier Circle', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
}


@media screen and (max-width: 768px) {
    body, #root {
        font-size: 18px;
    }
}

@media screen and (max-width: 576px) {
    body, #root {
        font-size: 14px;
    }
}