.subscribeForm {
    max-width: 700px;
    margin: 0 auto;
    padding-top: 3rem;
    text-align: left;
}

label.email {
    display: block;
    padding-bottom: 10px;
}

input.email {
    outline: 0;
    box-sizing: border-box;
    border: none;
    border-radius: 50px;
    height: 48px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 15rem;
    font-size: 1rem;
    z-index: 100;
}


input.email::placeholder {
    color: #8B8C7B;
}

.btnGroup {
    position: relative;
    padding-bottom: 10px;
}

.btnGroup button {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    text-align: center;
}

.subscribeBtn {
    cursor: pointer;
    outline: 0;
    box-sizing: border-box;
    border: none;
    border-radius: 50px;
    height: 48px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    z-index: 200;
    color: white;
    background-color: #FD9274;
}

.subscribeBtn:hover {
    background-color: #fc643a;
}

.subscribeBtn:active {
    background-color: #CA441E;
}

.unsub {
    min-width: 12rem;
}

button.subscribeBtn:disabled,
button.subscribeBtn[disabled],
button.subscribeBtn:disabled:hover,
button.subscribeBtn[disabled]:hover{
    background-color: #FD9274;
    cursor: unset;
    opacity: .7;
}

.gdpr {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: .7rem;
    line-height: 1rem;
    padding-left: 30px;
    margin-bottom: 0;
    user-select: none;
}

.gdpr input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: -1;
}

.gdprCheckmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: white;
}

.gdpr input:checked ~ .gdprCheckmark {
    background-origin: border-box;
    background: white url("../assets/images/checkmark.svg") no-repeat center;
}

.gdpr input:checked ~ span {
    border-color: #40A33F;
    color: #40A33F !important;
}

.policy {
    background: transparent;
    outline: none;
    border: none;
    color: #FD9274;
    padding: 0;
    margin: 0;
    font-size: inherit;
    cursor: pointer;
}

.policy-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-self: center;
    align-items: center;
}

.policy-btn-caption {
    padding-left: 10px;
}

.policy-btn svg path {
    fill: #FD9274;
}


.policy:focus {
    color: #FD9274;
}

.policy-btn:focus svg path {
    fill: #FD9274;
}

.policy:hover {
    color: #0288AE;
}

.policy-btn:hover svg path {
    fill: #0288AE;
}

.policy:active {
    color: white;
}

.policy-btn:active {
    color: black!important;
}

.policy-btn:active svg path {
    fill: black;
}


.requiredIcon {
    color: #F93D1C;
}

.successWrapper {
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    color: white;
    padding-top: 3rem;
    margin: 0 auto;
    text-align: center;
}

