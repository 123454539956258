.overlay {
    width: 100%;
    height: 100%;
    background: black;
    opacity: .35;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.bgndpicture {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-image: url('../assets/images/background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}