.container {
    text-align: center;
    height: 100%;
    min-height: 100vh;
    position: relative;
    color: white;
    z-index: 100;
    max-width: 60vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.policy-page-container {
    height: 100vh;
    position: relative;
    z-index: 100;
    max-width: 60vw;
    line-height: unset;
    overflow-y: auto;
}

.policy-main a:active {
    color: black !important;
}

.logo {
    pointer-events: none;
    width: 45%;
}

.header {
    padding-top: 5rem;
    padding-bottom: 1rem;
}

.policy-header {
    margin: 40px auto;
    text-align: center;
    position: relative;
}

.formWrapper {
    min-height: 30vh;
}

.footer {
    padding-bottom: 1rem;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: small;
    text-align: center;
}

.policy-footer {
    margin: 50px auto;
    text-align: center;
    position: relative;
    padding: 0 25%;
}

a, a:focus, a:active, a:hover {
    text-decoration: none;
}

a, a:focus {
    color: #FD9274;
}

a:hover {
    color: #0288AE;
}

a:active {
    color: white;
}

h1 {
    font-size: 4.5rem;
    line-height: 5rem;
    margin-bottom: 0;
    margin-top: 0;
}

.oneliner {
    margin-top: 20px;
    font-size: 2rem;
    line-height: 2.5rem;
    white-space: pre;
}

.breadtext {
    font-size: 1.2rem;
    line-height: 1.3rem;
    margin-top: 20px;
    font-weight: 400;
}

.login-btn-container {
    position: fixed;
    top: 0;
    right: 8rem;
    z-index: 1000;
    padding: 2rem;
    height: 48px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .container, .policy-page-container {
        max-width: 100%;
    }

    h1 {
        font-size: 3.5rem;
        line-height: 4rem;
    }

    .oneliner {
        margin-top: 20px;
        font-size: 1.75rem;
        line-height: 2rem;
        white-space: pre;
    }
}

@media screen and (max-width: 768px) {
    .container, .policy-page-container {
        max-width: 85%;
    }

    h1 {
        font-size: 2.75rem;
        line-height: 3.3rem;
    }
}

@media screen and (max-width: 576px) {
    h1 {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .oneliner {
        margin-top: 10px;
        font-size: 1.1rem;
        line-height: 1.4rem;
    }

    .breadtext {
        font-size: 0.8rem;
        line-height: 1.1rem;
        margin-top: 20px;
        font-weight: 500;
    }

    .header {
        padding-top: 0;
        padding-bottom: 2rem;
    }

    .subscribeBtn {
        font-size: .7rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .subscribeForm {
        padding-top: 1rem;
    }

    input.email {
        padding-right: 8rem;
    }

    .errorWrapper {
        padding-top: 1rem;
    }

    .logo {
        width: 30%;
    }

    .login-btn-container {
        right: 7rem;
        padding: 1rem;
    }

    .login-btn {
        height: 40px;
        padding: 0 2rem;
    }

    .formWrapper {
        margin-top: 40px;
    }
}
