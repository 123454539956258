.language-switch {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    padding: 3rem;
    font-size: small;
    color: white;
}

.globeIcon {
    position: absolute;
    top: 50%;
    left: 1.6rem;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.globeIcon svg {
    vertical-align: unset;
}

.globeIcon path {
    fill: white;
}

.language-switch input[type="radio"] {
    display: none;
}

.language-switch label {
    cursor: pointer;
}

.language-switch label:hover {
    color: #0288AE;
}

.language-switch label:active {
    color: white;
}

.language-switch input[type="radio"]:checked + label {
    color: #FD9274;
}

@media screen and (max-width: 576px) {
    .language-switch {
        padding: 2rem;
    }

    .globeIcon {
        left: .6rem;
    }
}
