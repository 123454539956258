@font-face {
    font-family: 'Basier Circle';
    src: url('BasierCircle-SemiBold.eot');
    src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
        url('BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('BasierCircle-SemiBold.woff2') format('woff2'),
        url('BasierCircle-SemiBold.woff') format('woff'),
        url('BasierCircle-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('BasierCircle-Medium.eot');
    src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
        url('BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
        url('BasierCircle-Medium.woff2') format('woff2'),
        url('BasierCircle-Medium.woff') format('woff'),
        url('BasierCircle-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Basier Circle';
    src: url('BasierCircle-Regular.eot');
    src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
        url('BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
        url('BasierCircle-Regular.woff2') format('woff2'),
        url('BasierCircle-Regular.woff') format('woff'),
        url('BasierCircle-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
